<template>
  <div id="newly2">
    <div class="main">
      <!-- 基础设置 -->
      <div class="title">基础设置</div>
      <div class="divider"></div>
      <div class="model">
        <div class="flex">
          <div class="left">
            <span class="red">*</span>
            方案名称
          </div>
          <div class="rigth">
            <el-input
              style="width: 618px"
              type="text"
              placeholder="请输入加密方案名称"
              v-model="post.template_name"
              maxlength="12"
              show-word-limit
            ></el-input>
          </div>
        </div>

        <div class="flex">
          <div class="left" style="margin-left: 5px">方案默认状态</div>
          <div class="rigth">
            <el-radio-group v-model="post.status">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="2">停用</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 水印保护 -->
      <div class="title2">
        水印保护
        <el-switch
          @change="statuss"
          style="margin-left: 20px"
          active-value="1"
          inactive-value="2"
          v-model="post.is_watermark"
        ></el-switch>
      </div>
      <div class="divider"></div>
      <div class="horse" v-if="post.is_watermark == 1">
        <div class="horse_left">
          <div class="model">
            <div class="flex" style="height: 50px">
              <div class="left">水印文件</div>
              <div class="rigth flex-center">
                <div class="photo">
                  <img :src="post.watermark_url" alt="" />
                </div>
                <div style="position: relative">
                  <changePhotoimg
                    :size="7"
                    @complete="complete"
                    class="changePhotoimg"
                  ></changePhotoimg>
                  <el-button
                    type=""
                    style="width: 130px; height: 50px"
                    class="ml10"
                  >
                    上传水印
                  </el-button>
                </div>

                <!-- <watermark
                  :isRestore="true"
                  :value.sync="post.watermark_url"
                ></watermark> -->
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex">
              <div class="left" style="margin-right: 90px">透明度</div>
              <div class="rigth">
                <el-select
                  style="width: 101px; margin-right: 30px"
                  v-model="post.watermark_opacity"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in getNumbers(0, 101)"
                    :key="item"
                    :label="item + '%'"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex">
              <div class="left">水印大小</div>
              <div class="rigth">
                <el-select
                  style="width: 101px; margin-right: 30px"
                  v-model="post.watermark_size"
                  size="small"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in getNumbers(0, 101)"
                    :key="item"
                    :label="item + '%'"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="model">
            <div class="flex1">
              <div class="left">出现位置</div>
              <div class="rigth">
                <el-radio-group v-model="post.watermark_position">
                  <div class="mb20">
                    <el-radio :label="2">右上角</el-radio>
                    <el-radio :label="4">右下角</el-radio>
                  </div>
                  <div class="mb20">
                    <el-radio :label="1">左上角</el-radio>
                    <el-radio :label="3">左下角</el-radio>
                  </div>
                  <div class="mb20">
                    <el-radio :label="5">居中画面</el-radio>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="horse_right" v-if="shuiyinstatus">
        <div
          style="
            width: 300px;
            background: #fff;
            width: 380px;
            padding: 20px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          "
        >
          <div class="right_font1">效果预览</div>
          <div class="rignt_tu">
            <div style="width: 100%; height: 100%" id="watermarkContain">
              <watermarkPreView :options="post"></watermarkPreView>
            </div>
          </div>
        </div>
      </div>
      <!-- 版权申明 -->
      <div class="title2">
        版权声明
        <el-switch
          @change="statuss"
          style="margin-left: 20px"
          active-value="1"
          inactive-value="2"
          v-model="post.is_copyright"
        ></el-switch>
      </div>
      <div class="divider"></div>
      <div v-if="post.is_copyright == 1">
        <div class="model">
          <div class="flex1">
            <div class="left">
              <span class="red">*</span>
              声明内容
            </div>
            <div class="rigth">
              <el-input
                class="input_bc"
                type="textarea"
                placeholder="请输入内容"
                v-model="post.copyright_content"
                maxlength="255"
                style="width: 472px"
                rows="7"
                show-word-limit
              ></el-input>
            </div>
          </div>
        </div>

        <div class="model">
          <div class="flex">
            <div class="left">出现位置</div>
            <div class="rigth">
              <el-radio-group v-model="post.copyright_position">
                <el-radio :label="1">上方</el-radio>
                <el-radio :label="2">中间</el-radio>
                <el-radio :label="3">下方</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="model">
          <div class="flex">
            <div class="left">轮播速度</div>
            <div class="rigth">
              <el-radio-group v-model="post.copyright_speed">
                <el-radio :label="1">高速</el-radio>
                <el-radio :label="2">中速</el-radio>
                <el-radio :label="3">低速</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <el-button
        @click="submit"
        type="primary"
        style="width: 130px; margin-top: 61px"
      >
        保存
      </el-button>
    </div>

    <loading v-show="isLoading" leftPos="0"></loading>
  </div>
</template>
<script>
import watermarkPreView from './watermarkPreView'

import changePhotoimg from '@cm/base/changePhotoimg'
// import watermark from '@/components/watermark'
//品牌保护新建
export default {
  name: 'newly2',

  components: {
    // watermark,
    watermarkPreView,
    changePhotoimg,
  },

  data() {
    return {
      isLoading: false,
      shuiyinstatus: true,
      imgstatus: true,
      post: {
        template_name: '',
        status: 1,
        watermark_opacity: 100,
        is_watermark: '1',
        watermark_size: 12,
        watermark_position: 3,
        is_copyright: '1',
        copyright_content: `【版权声明】本课程版权属于${this.$store.state.userInfo.jg_title}网校所有，仅限个人学习，严禁任何形式的录制、传播和账号分享，一经发现，平台依法保留追究权，情节严重者将承担法律责任`,
        copyright_position: 1,
        copyright_speed: 3,
        watermark_url: '',
      },
    }
  },
  created() {
    if (this.$route.query.status == 2) {
      this.post.watermark_url = ''
      this.getDate()
    } else {
      this.post.watermark_url = this.$store.state.userInfo.jg_logo
    }
  },

  mounted() {
    this.$root.$children[0].childPageOptions[
      this.$root.$children[0].childPageOptions.length - 1
    ].name = this.$route.query.name
  },
  methods: {
    complete(val) {
      this.post.watermark_url = val
    },
    statuss() {
      let status1 = this.post.is_copyright
      let status2 = this.post.is_watermark
      if (status1 == 2 && status2 == 2) {
        this.shuiyinstatus = false
      } else {
        this.shuiyinstatus = true
      }
    },

    getNumbers: function (start, stop) {
      return new Array(stop - start).fill(start).map((n, i) => n + i)
    },
    getDate() {
      this.isLoading = true
      this.$http({
        url: `/BrandSafeTemplate/editBrandSafe?template_id=${this.$route.query.template_id}`,
        callback: ({ data, code }) => {
          if (code == 200) {
            this.post = _.assign({}, this.post, {
              template_id: this.$route.query.template_id,
              template_name: data.template_name,
              status: Number(data.status),
              watermark_opacity: Number(data.watermark_opacity),
              is_watermark: data.is_watermark,
              watermark_size: Number(data.watermark_size),
              watermark_position: Number(data.watermark_position),
              is_copyright: data.is_copyright,
              copyright_content: data.copyright_content,
              copyright_position: Number(data.copyright_position),
              copyright_speed: Number(data.copyright_speed),
              watermark_url: data.watermark_url,
            })
            this.isLoading = false
          }
        },
        error: (error) => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
    del_logo() {
      this.imgstatus = false
    },
    submit() {
      const template_name = this.post.template_name
      if (
        template_name == 'undefined' ||
        template_name == null ||
        template_name == ''
      ) {
        this.$root.prompt({
          msg: '方案名不能为空',
        })
        return
      }
      if (this.post.is_watermark == 2 && this.post.is_copyright == 2) {
        this.$root.prompt({
          msg: '水印和版权声明必须有一项开启',
        })
        return
      }
      if (!this.post.copyright_content && this.post.is_copyright == 1) {
        this.$root.prompt({
          msg: '请输入版权声明内容',
        })
        return
      }
      if (this.$route.query.status == 1) {
        this.$nextTick(() => {
          this.$http({
            url: '/BrandSafeTemplate/addBrandSafe',
            data: this.post,
            callback: ({ code }) => {
              if (code == 200) {
                this.$root.prompt({
                  msg: '保存成功',
                  type: 'success',
                })
                this.$router.push({
                  path: '/videoSafe/brand',
                })
                // this.dateList = data
              }
            },
            error: (error) => {
              if (error.code == 204) {
                // this.dateList = []
              }
            },
          })
        })
      }
      if (this.$route.query.status == 2) {
        this.$nextTick(() => {
          this.$http({
            url: '/BrandSafeTemplate/editBrandSafe',
            data: this.post,
            callback: ({ code }) => {
              if (code == 200) {
                this.$root.prompt({
                  msg: '保存成功',
                  type: 'success',
                })
                this.$router.push({
                  path: '/videoSafe/brand',
                })
                // this.dateList = data
              }
            },
            error: (error) => {
              if (error.code == 204) {
                // this.dateList = []
              }
            },
          })
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
#newly2 {
  ::v-deep .el-textarea .el-input__count {
    background-color: #f5f5f5;
  }
  .main {
    padding: 20px;
    background-color: #fff;

    .title,
    .title2 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }

    .title2 {
      margin-top: 40px;
    }
    .divider {
      width: 100%;
      height: 1px;
      background: #e8e8e8;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    ::v-deep .el-textarea__inner {
      background-color: #f5f5f5;
    }

    .horse {
      display: flex;
      .horse_left {
        width: 540px;
        .rigth {
          .changePhotoimg {
            width: 150px;
            position: absolute;
            height: 50px;
          }
          .photo {
            object-fit: contain;
            border: 1px dashed rgb(151, 151, 151);
            width: 110px;
            height: 50px;
            // background-color: red;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }

    .horse_right {
      z-index: 1;
      position: fixed;
      top: 34%;
      left: 55%;
      padding-right: 20px;
      width: calc(100% - 540px);
      .right_font1 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 18px;
        margin-bottom: 21px;
      }
      .rignt_tu {
        width: 380px;
        height: 214px;
        position: relative;
        overflow: hidden;
        background-color: #000;
      }
    }
    .model {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
      margin-left: 20px;
      .flex {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .left {
          margin-right: 74px;
        }
        .left1 {
          margin-right: 42px;
        }
        .right_tu2 {
          height: 50px;
          width: 50px;
          cursor: pointer;
        }
        .check2 {
          ::v-deep .checkbox-group {
            width: 200px;
          }
        }
      }
      .flex1 {
        margin-top: 20px;
        display: flex;
        .left {
          margin-right: 74px;
        }
        .left1 {
          margin-right: 42px;
        }
      }
    }
  }
}
#watermarkContain {
  background: url('~@ass/img/1.1.5/img_xgyl.png') no-repeat center 100%/100%;
}
</style>
